<template>
  <v-card
    class="rounded-lg attractionCard d-flex px-0 flex-column"
    width="100%"
    min-height="100%"
    :class="{
      'px-1': !$vuetify.breakpoint.smAndDown,
      'disabled-card': attraction.status == 'Inactive',
    }"
  >
    <div style="width: 100%; height: 150px" class="flex-grow-0 px-3">
      <img
        :src="attraction.images[0].thumbs[1]"
        width="100%"
        height="150px"
        style="object-fit: cover; cursor: pointer; border-radius: 10px"
        class="mr-3"
        @click="
          pcVersion
            ? $router.push('/attractions/' + attraction.uid)
            : $router.push('/mobile/attractions/' + attraction.uid)
        "
      />
    </div>
    <p
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      class="flex-grow-0 title-label"
    >
      {{ getAttractionName }}
    </p>
    <p
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      class="flex-grow-0 px-3 desc-label"
    >
      {{ attraction.short_description }}
    </p>
    <div
      class="booking_text mb-2 px-3"
      v-if="attraction.booking_requirements"
      @click="showModal(attraction.booking_requirements)"
    >
      <div
        class="mt-0 booking-label"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      >
        {{ "booking_required_label" | localize }}
      </div>
    </div>
    <v-col class="px-3 py-0">
      <v-row no-gutters align="center" class="mb-2 flex-grow-0">
        <img
          src="@/assets/Vector.svg"
          class="info-icon"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        />
        <v-col cols="10" class="ml-2">
          <span
            ><a
              :href="`https://www.google.com/maps/search/?api=1&query=${attraction.geo_lat},${attraction.geo_lon}`"
              target="_blank"
              class="info-label"
              :class="{ mobile: $vuetify.breakpoint.smAndDown }"
              style="text-decoration: none; color: black"
              ><u>{{ attraction.address }}</u></a
            >
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="px-3 py-0" v-if="attraction.closest_stop">
      <v-row no-gutters align="center" class="mb-2">
        <img
          src="@/assets/Buses.svg"
          class="info-icon"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        />
        <v-col cols="10" class="ml-2">
          <span
            class="flex-grow-0 info-label"
            :class="{ mobile: $vuetify.breakpoint.smAndDown }"
          >
            {{ attraction.closest_stop }}
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-row no-gutters align="center" class="mb-2 px-3 flex-grow-0">
      <img
        src="@/assets/Time.svg"
        class="info-icon"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      />
      <v-col cols="10" class="pa-0 pl-2">
        <span
          v-for="(key, index) in attraction.working_hours"
          :key="key.id"
          class="info-label"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
        >
          <span v-if="key.day.length > 3">
            {{ key.day.substr(0, 3) | localize }}-{{
              key.day.substr(key.day.length - 3, key.day.length) | localize
            }}:&nbsp;{{ key.from + "-" + key.to
            }}<span v-if="index < attraction.working_hours.length - 1"
              >,&nbsp;</span
            ><br />
          </span>
          <span v-else>
            {{ key.day.substr(0, 3) | localize }}:&nbsp;{{
              key.from + "-" + key.to
            }}<span v-if="index < attraction.working_hours.length - 1"
              >,&nbsp;</span
            >
            <br />
          </span>
        </span>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row
      justify="center"
      class="flex-grow-0 mt-2"
      :class="{
        'px-1': $vuetify.breakpoint.smAndDown && locale == 'es',
        'px-3': !$vuetify.breakpoint.smAndDown || locale == 'en',
      }"
    >
      <v-col class="col-6 col-md-12">
        <v-btn
          block
          class="card-btn"
          :class="{
            'mobile rounded-lg': $vuetify.breakpoint.smAndDown,
            'px-0': $vuetify.breakpoint.smAndDown && locale == 'es',
            'px-1': !$vuetify.breakpoint.smAndDown || locale == 'en',
          }"
          color="#e62076"
          :height="$vuetify.breakpoint.smAndDown ? '21px' : '40px'"
          outlined
          @click="
            pcVersion
              ? $router.push('/attractions/' + attraction.uid)
              : $router.push('/mobile/attractions/' + attraction.uid)
          "
          >{{ "attractions_more_info" | localize }}</v-btn
        ></v-col
      >
      <v-col
        v-if="!disableSelect"
        class="col-6 col-md-12"
        :class="{ 'mt-0 pt-0': !$vuetify.breakpoint.smAndDown }"
      >
        <v-btn
          block
          :height="$vuetify.breakpoint.smAndDown ? '21px' : '40px'"
          :class="{ 'mobile rounded-lg': $vuetify.breakpoint.smAndDown }"
          class="pa-0 card-btn"
          :color="checked ? '#e62076' : '#1CC601'"
          @click="
            attraction.is_booking_require == 'Yes' && !checked
              ? $emit('bookingAttraction', attraction)
              : attraction.is_booking_require == 'No' && !checked
              ? setChecked(attraction.uid)
              : removeAttraction(attraction.uid)
          "
          :disabled="checked ? false : disableAgregarBtn"
          >{{
            checked ? "remove_button" : "city_passes_select_button" | localize
          }}</v-btn
        >
      </v-col>
    </v-row>
    <terms-modal
      v-if="isShowModal"
      rules="booking"
      @close_modal="
        isShowModal = false;
        text = null;
      "
      :text="text"
    />
  </v-card>
</template>

<script>
import TermsModal from "../../../UI/TermsModal.vue";
import userSettingsMixin from "@/mixins/userSettingsMixin";
import cityMixin from "@/mixins/cityMixin";

export default {
  components: { TermsModal },
  mixins: [userSettingsMixin, cityMixin],
  data: () => ({
    checked: false,
    isShowModal: false,
    text: null,
  }),
  props: {
    attraction: {
      required: true,
    },
    disableAgregarBtn: {
      required: true,
    },
    choosed: {
      require: false,
    },
    pcVersion: {
      require: false,
      default: false,
    },
    disableSelect: {
      require: false,
      default: false,
    },
  },
  mounted() {
    if (this.choosed) {
      if (!this.mazatlan) {
        this.setChecked(this.attraction.uid);
      } else {
        this.checked = true;
      }
    }
  },
  methods: {
    showModal(text) {
      this.isShowModal = true;
      this.text = text;
    },
    setChecked(uid) {
      this.$emit("setSelectedAttraction", uid);
      this.checked = !this.checked;
    },
    removeAttraction(uid) {
      this.$emit("removeAttractionUid", uid);
      this.checked = !this.checked;
    },
  },
  computed: {
    getAttractionName() {
      if (this.attraction.title.split(" ").length > 5) {
        return this.attraction.title.split(" ").splice(0, 5).join(" ");
      } else {
        return this.attraction.title;
      }
    },
  },
  watch: {
    choosed: {
      handler() {
        if (this.choosed) {
          if (!this.mazatlan) {
            this.setChecked(this.attraction.uid);
          } else {
            this.checked = true;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-card {
  opacity: 0.6;
}
.attractionCard {
  padding: 11px;
}
.booking_text {
  cursor: pointer;
}
.title-label {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #343434;
  text-align: center;
  margin-top: 10px;

  &.mobile {
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
  }
}
.desc-label {
  font-size: 12px;
  line-height: 13px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;

  &.mobile {
    font-size: 9px;
    line-height: 10px;
  }
}

.booking-label {
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  cursor: pointer;
  color: #3e81de;
  text-decoration: underline;

  &.mobile {
    font-size: 9px;
    line-height: 10px;
  }
}

.info-label {
  font-size: 12px;
  line-height: 13px;
  &.mobile {
    font-size: 9px;
    line-height: 10px;
  }
}
.info-icon {
  margin-top: 4px;
  align-self: start;
  &.mobile {
    margin-top: 6px;
    align-self: start;
  }
}
.card-btn {
  text-transform: none;
  color: white;
  font-size: 14px;
  line-height: 15px;
  &.mobile {
    font-size: 8px;
    line-height: 10px;
  }
}
</style>