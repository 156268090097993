<template>
  <div style="min-height: 80vh">
    <v-tabs-items v-model="type" touchless>
      <v-tab-item>
        <city-pass-component @set_citypass="setCityPass" />
      </v-tab-item>
      <v-tab-item>
        <attractions-component
          @to_type="toType"
          :selectedCitypass="selectedCitypass"
          :key="keyAttraction"
        />
      </v-tab-item>
      <v-tab-item>
        <checkout-component v-if="type == 2" @to_type="toType" />
      </v-tab-item>
    </v-tabs-items>
    <!-- <announcement-modal
      :visible="announcementModal"
      v-if="announcementModal"
      @close="announcementModal = false"
    /> -->
  </div>
</template>

<script>
import CityPassComponent from "./citypasses/CityPassComponent.vue";
import AttractionsComponent from "./attractions/AttractionsComponent.vue";
import CheckoutComponent from "./checkout/CheckoutComponent.vue";
//import announcementModal from "@/components/UI/announcementModal.vue";
import serverMixin from "@/mixins/serverMixin";
import cityMixin from "@/mixins/cityMixin";

export default {
  components: {
    CityPassComponent,
    AttractionsComponent,
    CheckoutComponent,
    //announcementModal,
  },
  mixins: [serverMixin, cityMixin],
  data: () => ({
    type: 0,
    selectedCitypass: {},
    keyAttraction: 0,
    //announcementModal: false,
  }),
  mounted() {
    this.setType();
    // if (localStorage.type) {
    //   this.type = parseInt(localStorage.type);
    // }
    if (localStorage.selectedCitypass) {
      this.selectedCitypass = JSON.parse(localStorage.selectedCitypass);
    }
  },
  methods: {
    setCityPass(citypass) {
      this.selectedCitypass = citypass;
      // this.type = 1
      this.$router.push({ name: "mobile_attraction" });
      // if (this.isProd && !this.mazatlan) {
      //   this.announcementModal = true;
      // } else {
      //   this.selectedCitypass = citypass;
      //   // this.type = 1
      //   this.$router.push({ name: "mobile_attraction" });
      // }
    },
    toType(type) {
      this.type = type;
    },
    setType() {
      if (this.$route.name == "mobile_citypass") {
        this.toType(0);
      } else if (this.$route.name == "mobile_attraction") {
        this.toType(1);
      } else if (this.$route.name == "mobile_checkout") {
        this.toType(2);
      }
    },
  },
  watch: {
    selectedCitypass: {
      handler() {
        localStorage.selectedCitypass = JSON.stringify(this.selectedCitypass);
        this.keyAttraction++;
      },
    },
    // type: {
    //   handler() {
    //     localStorage.type = this.type;
    //   },
    // },
    "$route.path": {
      deep: true,
      handler() {
        this.setType();
      },
    },
  },
};
</script>