<template>
  <div>
    <v-idle
      @idle="onidle"
      :events="['keydown', 'mousedown', 'touchstart']"
      :duration="5"
      :loop="true"
      hidden
    />
    <v-snackbar
      centered
      v-model="selectSnackbar.visible"
      color="deep-orange darken-1"
      :style="$vuetify.breakpoint.smAndDown ? 'top: -150px;' : 'top: -90px;'"
      timeout="10000"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          {{ "attractions_selected_label1" | localize }}
          <br />
          {{ "attractions_selected_label" | localize }}
        </v-col>
      </v-row>
    </v-snackbar>
    <v-card class="rounded-lg mx-3">
      <v-col>
        <v-row no-gutters align="center">
          <v-col class="col-4 mb-2"
            ><div class="labelStyle">Selected citypass:</div>
          </v-col>
          <v-col cols="2">
            <div></div>
          </v-col>
          <v-col
            class="col-6 text-center mb-2 font-weight-bold"
            style="color: #e62076"
          >
            <span
              style="
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #e62076;
              "
              >{{
                userLocale == "en"
                  ? selectedCitypass.title_en
                  : selectedCitypass.title_es
              }}</span
            >
          </v-col>
          <v-col
            class="px-0 py-0"
            :class="
              loggedUser
                ? loggedUser.locale == 'en'
                  ? ''
                  : 'mt-1'
                : user.locale == 'en'
                ? ''
                : 'mt-1'
            "
          >
            <span class="black--text labelStyle">
              {{ "attractions_starting_date" | localize }}
            </span>
          </v-col>
          <v-col
            ><v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="isClose"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row justify="start" no-gutters align="center">
                  <v-col cols="12" style="font-size: 12px">
                    <v-text-field
                      outlined
                      :value="
                        date
                          ? `${date.substr(5, 2)}/${date.substr(
                              8,
                              2
                            )}/${date.substr(0, 4)}`
                          : ''
                      "
                      :disabled="Object.entries(selectedCitypass).length == 0"
                      dense
                      class="rounded-lg"
                      background-color="white"
                      color="black"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      @click:append="on.click"
                      readonly
                      hide-details
                      :placeholder="
                        'attractions_placeholder_starting' | localize
                      "
                      @click="isClose = false"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <!--  :allowed-dates="allowedDates"  @blur="$v.date.$touch()" -->
              <v-date-picker
                v-model="date"
                color="#6ABAA4"
                :locale="loggedUser ? loggedUser.locale : user.locale"
                :allowed-dates="allowedDates"
                :min="
                  new Date(
                    new Date().getTime() +
                      1 * 60 * 60 * 1000 -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10)
                "
                :max="
                  new Date(
                    new Date().getTime() +
                      1464 * 60 * 60 * 1000 -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mb-1 mt-4" v-if="endDate">
          <v-col class="black--text py-0 labelStyle">End Date</v-col>
          <v-col class="black--text py-0" style="text-align: left">
            <v-text-field
              outlined
              :value="
                endDate.toISOString().substr(5, 2) +
                '/' +
                endDate.toISOString().substr(8, 2) +
                '/' +
                endDate.toISOString().substr(0, 4)
              "
              dense
              class="rounded-lg end-date"
              background-color="white"
              color="black"
              append-icon="mdi-calendar"
              readonly
              hide-details
              :placeholder="'attractions_placeholder_starting' | localize"
              @blur="$v.date.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="start" class="mt-3">
          <v-col>
            <p class="labelStyle">
              {{ "attractions_adult" | localize }}
            </p></v-col
          >
          <v-col>
            <v-row align="center" justify="center" class="px-1">
              <v-btn
                icon
                color="black"
                x-large
                :disabled="!selectedCitypassId || adultNumber == 0"
                @click="adultNumber > 0 ? adultNumber-- : (adultNumber = 0)"
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-col cols="2" class="px-0 py-0" style="text-align: center">
                <span class="black--text" style="font-weigth: 20px">{{
                  adultNumber
                }}</span>
              </v-col>
              <v-btn
                icon
                color="black"
                x-large
                @click="adultNumber < 15 ? adultNumber++ : (adultNumber = 15)"
                :disabled="!selectedCitypassId || adultNumber == 15"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="start">
          <v-col
            ><p class="labelStyle">
              {{ "attractions_child" | localize }}
            </p></v-col
          >
          <v-col style="top: 50%"
            ><v-row align="center" justify="center" class="px-1">
              <v-btn
                icon
                color="black"
                x-large
                @click="childNumber > 0 ? childNumber-- : (childNumber = 0)"
                :disabled="!selectedCitypassId || childNumber == 0"
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-col class="px-0 py-0" cols="2" style="text-align: center">
                <span class="black--text" style="font-weigth: 20px">{{
                  childNumber
                }}</span>
              </v-col>
              <v-btn
                icon
                color="black"
                x-large
                @click="childNumber < 40 ? childNumber++ : (childNumber = 40)"
                :disabled="!selectedCitypassId || childNumber == 40"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-row></v-col
          >
        </v-row>
        <p>
          {{ "attractions_infants_label" | localize }}&nbsp;{{
            "attractions_infants_label_2" | localize
          }}
        </p>
      </v-col>
      <!-- GDL Bus/Metro -->
      <v-row
        no-gutters
        justify="center"
        align="center"
        v-if="isAdditionalAttraction"
      >
        <v-divider color="black" style="border: 0.5px solid #000000" />
        <div
          style="
            width: 30px;
            height: 28px;
            object-fit: cover;
            margin-left: 21px;
            margin-right: 21px;
          "
        >
          <our-attraction-bus-icon color="black" />
        </div>
        <v-divider color="black" style="border: 0.5px solid #000000" />
      </v-row>
      <v-row
        no-gutters
        justify="center"
        align="center"
        class="px-0"
        style="margin-top: 10px; padding-bottom: 15px"
        v-if="isAdditionalAttraction"
      >
        <div style="width: 28px; height: 28px">
          <img
            src="@/assets/notChecked.svg"
            style="cursor: pointer"
            v-if="!isIncludeGDLPublicTransport"
            @click="isIncludeGDLPublicTransport = true"
          />
          <div style="" v-if="isIncludeGDLPublicTransport">
            <img
              src="@/assets/chekedTerm.svg"
              @click="isIncludeGDLPublicTransport = false"
              style="cursor: pointer"
            />
          </div>
        </div>

        <v-col cols="10" class="px-0 pl-4">
          <span
            style="
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
            "
            >{{ "public_transport_label1" | localize
            }}<u style="cursor: pointer" @click="showTermsModal = true">{{
              "attractions_include_transport_label" | localize
            }}</u
            >{{ "public_transport_label3" | localize }}</span
          >
        </v-col>
      </v-row>
      <!-- /GDL Bus/Metro -->
      <terms-modal
        v-if="showTermsModal"
        rules="publicTransport"
        @close_modal="showTermsModal = false"
      />
      <v-btn
        @click="addToCart(selectedCitypass.uuid)"
        class="btn_inner"
        :style="isMobile && isMobileMenu ? 'bottom: 95px;' : ''"
        :class="{
          'btn_inner-disabled': loadingCart || disabledCart,
        }"
        color="#e62076"
        dark
        v-if="
          selectedCitypassId && date && (adultNumber > 0 || childNumber > 0) && disableBuyBtn
        "
      >
        {{ "attractions_add_to_cart_button" | localize }}
      </v-btn>
    </v-card>
    <confirm-modal
      v-if="noGdlModal"
      :visible="noGdlModal"
      @close_modal="noGdlModal = false"
      @choosed="choosedConfirm"
      :typeConfirm="typeConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ourAttractionBusIcon from "../../UI/icons/ourAttractionBusIcon.vue";
import TermsModal from "../../UI/TermsModal.vue";
import CartService from "@/requests/Cart/CartService";
import confirmModal from "@/components/OurAttractions/confirmModal.vue";
import mobile from "@/mixins/mobileMixin";
import { eventBus } from "../../../main";
export default {
  components: { ourAttractionBusIcon, TermsModal, confirmModal },
  mixins: [mobile],
  data: () => ({
    adultNumber: 0,
    childNumber: 0,
    selectedCitypassId: "fdsfsdf",
    menu: null,
    endDate: null,
    date: "",
    userLocale: "",
    allowedDates: "",
    mapClass: "fullsize",
    attractions: [],
    transportAttractionPrice: 0,
    isClose: false,
    selectedAttractionUid: [],
    showTermsModal: false,
    isIncludeGDLPublicTransport: false,
    noGdlModal: false,
    typeConfirm: "",
    disabledCart: false,
    selectSnackbar: {
      visible: false,
      used: false,
    },
  }),
  props: {
    selectedCitypass: {
      require: true,
    },
    disableBuyBtn: {
      require: true,
    },
    isAdditionalAttraction: {
      require: true,
    },
    loadingCart: {
      require: true,
    },
  },
  mounted() {
    if (
      this.selectedCitypass.days.length &&
      this.selectedCitypass.days != "1,2,3,4,5,6,7"
    ) {
      console.log(this.selectedCitypass.days, this.allowedDates);
      this.allowedDates = (val) =>
        (new Date(val).getDay() >= this.selectedCitypass.days.substr(0, 1) &&
          new Date(val).getDay() <= this.selectedCitypass.days.substr(-1)) ||
        (new Date(val).getDay() == 0 &&
          this.selectedCitypass.days.substr(-1) == "7");
    }
    console.log(this.selectedCitypass.days, this.allowedDates);
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;

    if (localStorage.date) {
      this.date = localStorage.date;
      setTimeout(() => {
        this.emitData();
      }, 500);
    }

    if (localStorage.adultNumber) {
      this.adultNumber = localStorage.adultNumber;
    }

    if (localStorage.childNumber) {
      this.childNumber = localStorage.childNumber;
    }

    if (localStorage.isIncludeGDLPublicTransport) {
      this.isIncludeGDLPublicTransport =
        localStorage.isIncludeGDLPublicTransport;
    }
  },
  methods: {
    onidle() {
      if (
        this.selectedCitypass.uuid &&
        this.disableBuyBtn &&
        (!this.date ||
          parseInt(this.adultNumber) + parseInt(this.childNumber) == 0) &&
        !this.selectSnackbar.used
      ) {
        this.selectSnackbar.visible = true;
        this.selectSnackbar.used = true;
      }
    },
    choosedConfirm(check) {
      this.noGdlModal = false;
      if (this.typeConfirm == "cart") {
        if (check) {
          if (
            !this.isIncludeGDLPublicTransport &&
            this.isAdditionalAttraction
          ) {
            this.typeConfirm = "noBus";
            setTimeout(() => {
              this.noGdlModal = true;
            }, 200);
          } else {
            this.$emit(
              "addToCart",
              this.selectedCitypass.uuid,
              this.date,
              this.adultNumber,
              this.childNumber,
              this.isIncludeGDLPublicTransport
            );
            this.disabledCart = false;
          }
        } else {
          this.disabledCart = false;
        }
      } else {
        this.isIncludeGDLPublicTransport = check;
        this.$emit(
          "addToCart",
          this.selectedCitypass.uuid,
          this.date,
          this.adultNumber,
          this.childNumber,
          this.isIncludeGDLPublicTransport
        );
        this.disabledCart = false;
      }
    },
    addToCart() {
      console.log(this.date);
      if (this.adultNumber !== 0) {
        this.checkCitypassInCart();
      }
    },
    async checkCitypassInCart() {
      this.disabledCart = true;
      this.typeConfirm = "";
      let cart = await CartService.getCart();
      let response = cart.result.list;

      let check = false;
      response.forEach((el) => {
        if (
          el.citypass_uuid == this.selectedCitypass.uuid &&
          el.started_at.substr(0, 10) == this.date
        ) {
          this.noGdlModal = true;
          this.typeConfirm = "cart";
          check = true;
          return;
        }
      });

      if (!check) {
        if (!this.isIncludeGDLPublicTransport && this.isAdditionalAttraction) {
          this.noGdlModal = true;
          this.typeConfirm = "noBus";
        } else {
          this.$emit(
            "addToCart",
            this.selectedCitypass.uuid,
            this.date,
            this.adultNumber,
            this.childNumber,
            this.selectedCitypass.attraction_count,
            this.isIncludeGDLPublicTransport
          );
          this.disabledCart = false;
          // this.selectedCitypassId = "";
          // this.selectedCitypass = {};
        }
      }
    },
    emitData() {
      console.log("work emit data");
      eventBus.$emit(
        "changeData",
        this.date,
        this.endDate,
        this.adultNumber,
        this.childNumber
      );
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    disableBuyBtn: {
      handler() {
        this.selectSnackbar.used = false;
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    isIncludeGDLPublicTransport: {
      handler() {
        if (this.isIncludeGDLPublicTransport) {
          localStorage.isIncludeGDLPublicTransport =
            this.isIncludeGDLPublicTransport;
        } else {
          localStorage.removeItem("isIncludeGDLPublicTransport");
        }
      },
    },
    adultNumber: {
      deep: true,
      handler() {
        if (this.adultNumber) {
          localStorage.adultNumber = this.adultNumber;
          this.emitData();
        }
      },
    },
    childNumber: {
      deep: true,
      handler() {
        if (this.childNumber) {
          localStorage.childNumber = this.childNumber;
          this.emitData();
        }
      },
    },
    date: {
      deep: true,
      handler() {
        if (this.date) {
          localStorage.date = this.date;
        }

        this.isClose = true;
        if (this.selectedCitypass && this.date) {
          var endDate = new Date(this.date);
          if (
            this.selectedCitypass.days &&
            this.selectedCitypass.days != "1,2,3,4,5,6,7"
          ) {
            let remainderDays = 0;
            remainderDays =
              this.selectedCitypass.days.substr(-1) -
              new Date(this.date).getDay();
            endDate.setDate(endDate.getDate() + parseInt(remainderDays));

            if (remainderDays == 7) {
              remainderDays = 0;
            }
            if (
              parseInt(remainderDays) + 1 <
              parseInt(this.selectedCitypass.term)
            ) {
              this.$emit("show_snackbar", remainderDays);
            }
          } else {
            endDate.setDate(
              endDate.getDate() + parseInt(this.selectedCitypass.term) - 1
            );
          }
          this.endDate = endDate;
          this.emitData();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.labelStyle {
  font-size: 18px;
}
.btn_inner {
  border-bottom: 2px solid #9a104c;
  border-radius: 10px !important;
  width: 342px !important;
  height: 44px !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  z-index: 110;
  left: 50%;
  margin-top: -22px;
  margin-left: -171px;
  text-transform: none;

  &-disabled {
    background-color: rgb(215, 215, 215) !important;
    border-bottom: 2px solid #acacac !important;
    pointer-events: none;
  }
}
.end-date {
  color: black !important;
  pointer-events: none;
  cursor: pointer;
  opacity: 0.6;
}
</style>