import { mapActions, mapGetters } from 'vuex';
import * as actionTypes from '@/store/modules/mztAttractions/types/actions';
import * as getterTypes from '@/store/modules/mztAttractions/types/getters';

export default {
    methods: {
        ...mapActions('mztAttractions', {
            setChoosedInfo: actionTypes.FETCH_DATA,
        }),
    },
    computed: {
        ...mapGetters('mztAttractions', {
            getChoosedInfo: getterTypes.GET_DATA,
        }),
    },
};