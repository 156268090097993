<template>
  <v-container class="mobile-container">
    <mobile-component />
  </v-container>
</template>

<script>
import MobileComponent from "@/components/mobile/MobileComponent.vue";
export default {
  components: {
    MobileComponent,
  },
};
</script>

<style scoped>
.mobile-container {
    max-width: 600px !important
}
</style>