<template>
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 8C4.5 5.51472 6.51472 3.5 9 3.5H21C23.4853 3.5 25.5 5.51472 25.5 8V23C25.5 23.2761 25.2761 23.5 25 23.5H5C4.72386 23.5 4.5 23.2761 4.5 23V8Z"
      :stroke="color"
    />
    <rect x="4.5" y="7.5" width="21" height="8" :stroke="color" />
    <circle cx="9" cy="19" r="2" :fill="color" />
    <circle cx="21" cy="19" r="2" :fill="color" />
    <rect x="6" y="23" width="4" height="4" rx="1" :fill="color" />
    <rect x="20" y="23" width="4" height="4" rx="1" :fill="color" />
    <rect x="25" y="12" width="2" height="4" rx="1" :fill="color" />
    <rect x="3" y="12" width="2" height="4" rx="1" :fill="color" />
    <rect x="15" y="7" width="1" height="9" rx="0.5" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      require: true,
    },
  },
};
</script>

<style>
</style>