<template>
  <div>
    <v-btn
      @click="$router.push('/')"
      class="mt-1"
      color="black"
      icon
      absolute
      x-large
    >
      <v-row no-gutters justify="center" align="center">
        <v-col class="col-12 pa-0">
          <!-- <v-icon x-large style="height: 100%; width: auto"
              >mdi-arrow-left
            </v-icon> -->
          <img src="@/assets/mobileBackButton.svg" />
        </v-col>
        <!-- <v-col class="col-12 pa-0" style="font-size: 12px; line-height: 12px"
            >back</v-col
          > -->
      </v-row>
    </v-btn>
    <v-row no-gutters justify="center">
      <div class="step-value text-center"><div class="step-number">1</div></div>
      <div class="step-value-grey mx-2 text-center">
        <div class="step-number">2</div>
      </div>
      <div class="step-value-grey text-center">
        <div class="step-number">3</div>
      </div>
    </v-row>
    <v-row no-gutters justify="center" class="step-title">
      {{ "attractions_choose_city_pass" | localize }}
    </v-row>
    <v-row align="center" justify="center" no-gutters>
      <v-radio-group
        v-model="radio"
        row
        @change="changedFilter"
        mandatory
        hide-details
        class="mt-0"
      >
        <v-radio :label="'city_passes_all' | localize" value="All"></v-radio>
        <v-radio :label="'city_passes_tourists' | localize" value="Tourists">
        </v-radio>
        <v-radio :label="'city_passes_locals' | localize" value="Locals">
        </v-radio>
      </v-radio-group>
    </v-row>

    <!-- TOURISTS -->
    <v-row
      v-if="radio == 'Tourists' || radio == 'All'"
      class="pb-2"
      align="center"
      justify="center"
    >
      <v-row no-gutters align="center" class="text-center">
        <v-col class="col-12">
          <v-divider class="pink darken-1" />
        </v-col>
        <v-col class="col-12 text-center my-2">
          <h1
            class="mx-3"
            :style="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'font-size: 20px'
                : ''
            "
          >
            {{ "city_passes_for_tourists_label" | localize }}
          </h1>
        </v-col>
        <v-col class="col-12">
          <v-divider class="pink darken-1" />
        </v-col>
      </v-row>

      <v-col
        class="col-12 px-5"
        v-for="(citypass, index) in touristsList"
        :key="index"
      >
        <citypass-tourists-card
          @set_citypass="$emit('set_citypass', citypass)"
          :citypass="citypass"
        />
      </v-col>
      <v-col class="col-12" v-if="loading && touristsList.length == 0">
        <div class="d-flex align-center" style="min-height: 300px">
          <v-progress-circular
            v-if="loading"
            class="mx-auto"
            indeterminate
            color="#E62076"
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col class="col-12" v-if="!loading && touristsList.length == 0">
        <div class="d-flex align-center" style="min-height: 300px">
          <v-row justify="center"
            ><span>{{
              "city_passes_label_no_city_passes" | localize
            }}</span></v-row
          >
        </div>
      </v-col>
    </v-row>
    <!-- END TOURISTS -->

    <!-- LOCALS -->
    <v-row
      v-if="radio == 'Locals' || radio == 'All'"
      class="pb-2"
      align="center"
      justify="center"
    >
      <v-row no-gutters align="center" class="text-center">
        <v-col class="col-12">
          <v-divider class="pink darken-1" />
        </v-col>
        <v-col class="col-12 text-center my-2">
          <h1
            class="mx-3"
            :style="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'font-size: 20px'
                : ''
            "
          >
            {{ "city_passes_for_locals" | localize }}
          </h1>
        </v-col>
        <v-col class="col-12">
          <v-divider class="pink darken-1" />
        </v-col>
      </v-row>
      <v-col
        class="col-12 px-5"
        v-for="(citypass, index) in localsList"
        :key="index"
      >
        <citypass-locals-card
          @set_citypass="$emit('set_citypass', citypass)"
          :citypass="citypass"
        />
      </v-col>
      <v-col class="col-12" v-if="loading && localsList.length == 0">
        <div class="d-flex align-center" style="min-height: 300px">
          <v-progress-circular
            v-if="loading"
            class="mx-auto"
            indeterminate
            color="#E62076"
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col class="col-12" v-if="!loading && localsList.length == 0">
        <div class="d-flex align-center" style="min-height: 300px">
          <v-row justify="center"
            ><span>{{
              "city_passes_label_no_city_passes" | localize
            }}</span></v-row
          >
        </div>
      </v-col>
    </v-row>

    <!-- END LOCALS -->
  </div>
</template>

<script>
import CitypassService from "@/requests/Citypass/CitypassService";
import CitypassTouristsCard from "./CitypassTouristsCard.vue";
import CitypassLocalsCard from "./CityPassLocalsCard.vue";

export default {
  components: {
    CitypassTouristsCard,
    CitypassLocalsCard,
  },
  data: () => ({
    radio: "All",
    cityPasses: [],
    filteredCityPasses: [],
    loading: false,
    touristsList: [],
    localsList: [],
  }),
  mounted() {
    this.$vuetify.goTo(0, 0);
    this.getCityPasses();
  },
  methods: {
    changedFilter() {
      this.localsList = [];
      this.touristsList = [];
      if (this.radio == "Tourists") {
        this.touristsList = this.cityPasses.filter((el) => {
          return el.type == "0";
        });
      } else if (this.radio == "Locals") {
        this.localsList = this.cityPasses.filter((el) => {
          return el.type == "1";
        });
      } else {
        this.touristsList = this.cityPasses.filter((el) => {
          return el.type == "0";
        });

        this.localsList = this.cityPasses.filter((el) => {
          return el.type == "1";
        });
        this.filteredCityPasses = this.touristsList.concat(this.localsList);
      }
    },
    async getCityPasses() {
      this.loading = true;
      await CitypassService.getCitypasses().then((res) => {
        this.cityPasses = res.result;
        this.changedFilter();
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.step-value {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 1px #E62076 solid;
  align-items: center;

  .step-number {
    color: #E62076;
    font-size: 36.7374px;
    line-height: 43px;
    margin-top: 8px;
    font-weight: bold;
  }

  &-grey {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 1px #737373 solid;
    align-items: center;
    .step-number {
      color: #737373;
      font-size: 36.7374px;
      line-height: 43px;
      margin-top: 8px;
      font-weight: bold;
    }
  }
}
.step-title {
  font-size: 26px;
  line-height: 43px;
  font-weight: bold;
  color: #000000;
}
</style>