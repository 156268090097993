<template>
  <div>
    <snackbar-success
      v-if="snackbar"
      :snackbar="snackbar"
      :snackbarText="snackbarText"
      :boldText="true"
    />
    <v-snackbar
      centered
      v-model="choseAttractionSnackbarSuccess"
      color="success"
      timeout="8000"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert-circle-outline</v-icon>
        <v-col>
          <span class="font-weight-bold"
            >{{ "attractions_selected_alert" | localize }}
            {{ parseInt(selectedCitypass.discount_attraction * 100) }}%
            {{ "attractions_selected_alert_2" | localize }}
            {{ parseInt(selectedCitypass.discount_attraction_max * 100) }}%
            {{ "attractions_selected_alert_3" | localize }}</span
          >
        </v-col>
      </v-row></v-snackbar
    >
    <v-snackbar
      top
      v-model="dateSnackbar"
      :style="choseAttractionSnackbarSuccess ? 'top: 80px' : ''"
      color="deep-orange darken-1"
    >
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          <span
            >{{ "attractions_calendar_reminder_popup1" | localize }}
            {{ remainderDays + 1 }}
            {{ "attractions_calendar_reminder_popup2" | localize }}</span
          >
        </v-col>
      </v-row>
    </v-snackbar>
    <v-btn
      @click="backToCitypass"
      class="mt-1"
      color="black"
      icon
      absolute
      x-large
    >
      <v-row no-gutters justify="center" align="center">
        <v-col class="col-12 pa-0">
          <!-- <v-icon x-large style="height: 100%; width: auto"
            >mdi-arrow-left
          </v-icon> -->
          <img src="@/assets/mobileBackButton.svg" />
        </v-col>
        <!-- <v-col class="col-12 pa-0" style="font-size: 12px; line-height: 12px"
          >back</v-col
        > -->
      </v-row>
    </v-btn>
    <v-row no-gutters justify="center" class="mb-5">
      <div class="step-value-grey text-center">
        <div class="step-number">1</div>
      </div>
      <div class="step-value mx-2 text-center">
        <div class="step-number">2</div>
      </div>
      <div class="step-value-grey text-center">
        <div class="step-number">3</div>
      </div>
    </v-row>
    <attraction-buy-form
      :selectedCitypass="selectedCitypass"
      :disableBuyBtn="disableAgregarBtn"
      :isAdditionalAttraction="isAdditionalAttraction"
      @addToCart="addToCart"
      :key="key"
      @show_snackbar="setSnackbar"
      :loadingCart="loadingCart"
    />
    <v-row no-gutters justify="center" class="mt-1">
      <label
        style="
          font-size: 28px;
          line-height: 32px;
          font-weight: 600;
          color: #000000;
          margin-top: 26px;
          text-align: center;
        "
        v-if="selectedCitypass"
      >
        {{ "mobile_version_select_attractions_label1" | localize }}
        <span v-if="!mazatlan">{{ selectedCitypass.attraction_count }}</span>
        <span v-if="mazatlan">{{ activeCategory.count }}</span>
        {{ "city_passes_label_attractions" | localize }}
        <span v-if="mazatlan"
          >{{ "select_attractions_label2" | localize }}
          {{ activeCategory.uuid }}</span
        >:
      </label>
    </v-row>
    <loader v-if="showLoader" />
    <attraction-list
      v-if="!showLoader && !mazatlan"
      @setSelectedAttraction="setSelectedAttraction"
      :disableAgregarBtn="disableAgregarBtn"
      :attractionList="attractionsList"
      :citypassFreeActtraction="selectedCitypass.attraction_count"
      :key="key + 1"
    />
    <mazatlan-attraction-list
      ref="mazatlan_list"
      v-if="!showLoader && mazatlan"
      :disableAgregarBtn="disableAgregarBtn"
      :attractionCategories="attractionCategories"
      :selectedItems="selectedAttractionUid"
      @setSelectedAttraction="setSelectedAttraction"
      @setActiveCategory="setActiveCategory"
      @set_tab="setTab"
      :key="key + 1"
    />
    <cart-component @close="closeCart" v-if="isCart" @get_cart="getCart" />
  </div>
</template>

<script>
import AttractionBuyForm from "./attractionBuyForm.vue";
import attractionList from "./attractionList/attractionList.vue";
import mazatlanAttractionList from "./attractionList/mazatlanAttractionList.vue";
import CartService from "../../../requests/Cart/CartService";
import CartComponent from "../../Cart/cartComponent.vue";
import AttractionsService from "../../../requests/Attractions/AttractionsService";
import Loader from "../../UI/Loader.vue";
import cityMixin from "@/mixins/cityMixin";
import mztAttractionsMixin from "@/mixins/mztAttractionsMixin";
import _ from "lodash";

export default {
  components: {
    attractionList,
    AttractionBuyForm,
    CartComponent,
    Loader,
    mazatlanAttractionList,
  },
  mixins: [cityMixin, mztAttractionsMixin],
  data: () => ({
    selectedAttractionUid: [],
    attractionsList: [],
    additionalAttractionUuid: [],
    isCart: false,
    disableAgregarBtn: false,
    key: 0,
    showLoader: true,
    dateSnackbar: false,
    snackbar: false,
    snackbarText: "",
    choseAttractionSnackbarSuccess: false,
    isAdditionalAttraction: false,
    remainderDays: 0,
    loadingCart: false,

    // mazatlan categories
    attractionCategories: [],
    activeCategory: {},
    passedCategories: [],
    tab: 0,
  }),
  props: {
    selectedCitypass: {
      require: true,
    },
  },
  destroyed() {
    if (this.mazatlan) {
      if (this.selectedCitypass.uuid && this.selectedAttractionUid.length) {
        const data = {
          tab: this.tab,
          attractions: this.selectedAttractionUid,
        };
        this.setChoosedInfo(data);
      }
    }
  },
  mounted() {
    this.$vuetify.goTo(0, 0);
    if (this.selectedCitypass) {
      this.getAttractions();
    }
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    setSnackbar(count) {
      this.remainderDays = count;
      this.dateSnackbar = true;
    },
    async addToCart(
      uuid,
      date,
      quantity_adult,
      quantity_kid,
      //   free_attraction_count,
      includeGDLPublicTransport
    ) {
      this.loadingCart = true;
      if (includeGDLPublicTransport == true) {
        this.additionalAttractionUuid.forEach((attraction) => {
          this.setSelectedAttraction(attraction.uuid);
        });
      }
      let data = {
        citypass_uuid: uuid,
        quantity_adult: quantity_adult,
        quantity_kid: quantity_kid,
        started_at: date,
        attractions: this.selectedAttractionUid,
      };
      let response = await CartService.addToCart(data);
      if (response.status == "success") {
        this.snackbarText = "attractions_city_pass_added_to_cart_notification";
        this.snackbar = true;
        this.snackbarSuccess = true;
        if (localStorage.count) {
          let count = JSON.parse(localStorage.count);
          localStorage.count = count + 1;
        } else {
          localStorage.count = 1;
        }
        this.selectedAttractionUid = [];
        // this.disableAgregarBtn = false;
        //this.key++;
        // localStorage.attractions = JSON.stringify(this.selectedAttractionUid);
        setTimeout(() => {
          this.showCart();
          //this.$router.push({ name: "mobile_checkout" });
          this.loadingCart = false;
        }, 700);
        // setTimeout(this.$router.push({name: 'mobile_checkout'}), 700);
      } else {
        this.loadingCart = false;
      }
    },
    setSelectedAttraction(list) {
      this.selectedAttractionUid = [];
      list.forEach((attraction) => {
        if (
          this.selectedAttractionUid.findIndex(
            (el) => el.attraction_uuid == attraction.attraction_uuid
          ) == -1
        ) {
          attraction.bookingEventUid
            ? this.selectedAttractionUid.push({
                attraction_uuid: attraction.attraction_uuid,
                event_uid: attraction.bookingEventUid,
              })
            : this.selectedAttractionUid.push({
                attraction_uuid: attraction.attraction_uuid,
              });
        }
      });
      // if (this.selectedAttractionUid.findIndex((el) => el === uid) == -1) {
      //   if (event_uid) {
      //     this.selectedAttractionUid.push({ attraction_uuid: uid });
      //   } else {
      //     this.selectedAttractionUid.push({ attraction_uuid: uid });
      //   }
      if (
        this.selectedCitypass.attraction_count ==
        this.selectedAttractionUid.length
      ) {
        this.disableAgregarBtn = true;
      } else {
        this.disableAgregarBtn = false;
      }
      localStorage.attractions = JSON.stringify(this.selectedAttractionUid);

      if (
        this.selectedAttractionUid.length ==
        this.selectedCitypass.attraction_count
      ) {
        this.choseAttractionSnackbarSuccess = true;
      }
      // }
    },
    removeAttractionUid(uid) {
      this.selectedAttractionUid = this.selectedAttractionUid.filter(
        (attractionUid) => attractionUid !== uid
      );

      localStorage.attractions = JSON.stringify(this.selectedAttractionUid);
    },
    setActiveCategory(category) {
      this.activeCategory = category;
    },
    closeCart() {
      // this.snackbar = false;
      // this.snackbarText = "";
      this.isCart = false;
    },
    getCart(count) {
      console.log(count);
    },
    showCart() {
      this.isCart = true;
    },
    backToCitypass() {
      // localStorage.setItem("type", 0);
      this.selectedAttractionUid = [];
      this.disableAgregarBtn = false;
      this.key++;

      // if (this.mazatlan) {
      //   this.$refs.mazatlan_list.resetData()
      // }
      this.$router.push({ name: "mobile_citypass" });
    },
    async getAttractions() {
      let filteredAttractions = this.selectedCitypass.attractions.filter(
        (attraction) =>
          attraction.is_free == "0" && attraction.is_additional == "0"
      );
      this.additionalAttractionUuid = this.selectedCitypass.attractions.filter(
        (attraction) => attraction.is_additional == "1"
      );
      if (this.additionalAttractionUuid.length == 0) {
        this.isAdditionalAttraction = false;
      } else {
        this.isAdditionalAttraction = true;
      }
      let strUid = "";
      filteredAttractions.forEach((attraction) => {
        strUid = strUid + `${attraction.uuid},`;
      });
      if (strUid !== "") {
        await AttractionsService.getAttractionsByUids(strUid).then((res) => {
          if (res.result !== null) {
            this.filterAttractions(res.result);
            this.showLoader = false;
          }
        });
      }
    },
    filterAttractions(attractions) {
      this.attractionsList = attractions;
      if (!this.admin) {
        this.attractionsList = this.attractionsList.filter(
          (attr) => attr.status == "Active"
        );
      }
      let filteredWorkingHours = [];

      for (let i = 0; i < this.attractionsList.length; i++) {
        if (typeof this.attractionsList[i].working_hours == "object") {
          filteredWorkingHours.push(this.attractionsList[i]);
        }
      }
      this.attractionsList = filteredWorkingHours;
      for (let index = 0; index < this.attractionsList.length; index++) {
        this.working_hoursValues = Object.values(
          this.attractionsList[index].working_hours
        );
        let working_hoursKey = Object.keys(
          this.attractionsList[index].working_hours
        );
        let working_hours = [];
        for (let i = 0; i < this.working_hoursValues.length; i++) {
          working_hours.push({
            day: working_hoursKey[i],
            from: this.working_hoursValues[i].from,
            to: this.working_hoursValues[i].to,
          });
        }
        var helper = {};
        var result = working_hours.reduce(function (groupHours, hours) {
          var key = hours.to + "-" + hours.from;
          if (!helper[key]) {
            helper[key] = Object.assign({}, hours); // create a copy of hours
            groupHours.push(helper[key]);
          } else {
            helper[key].day += "-" + hours.day;
          }

          return groupHours;
        }, []);
        this.attractionsList[index].working_hours = [...result];
      }

      if (this.mazatlan) {
        this.filterToCategories();
      }
    },
    filterToCategories() {
      this.attractionCategories = [];
      this.selectedCitypass.attraction_categories.forEach((category) => {
        const attractions = this.attractionsList.filter((el) => {
          return el.category == category.uuid;
        });
        this.attractionCategories.push({
          count: parseInt(category.count),
          uuid: category.uuid,
          attractions: attractions,
        });

        this.activeCategory = this.attractionCategories[0];
      });

      try {
        const data = { ...this.getChoosedInfo };
        console.log(data);
        if (!_.isEmpty(data)) {
          this.selectedAttractionUid = data.attractions;
        }
      } catch (e) {
        console.log(e);
      }

      console.log(this.attractionCategories);
    },
  },
  watch: {
    selectedAttractionUid: {
      deep: true,
      handler() {
        if (
          this.selectedCitypass.attraction_count ==
          this.selectedAttractionUid.length
        ) {
          this.disableAgregarBtn = true;
        } else {
          this.disableAgregarBtn = false;
        }
      },
    },
    selectedCitypass: {
      deep: true,
      handler() {
        this.selectedAttractionUid = [];
        this.disableAgregarBtn = false;
        this.getAttractions();
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        this.key++;
      },
    },
  },
};
</script>