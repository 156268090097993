<template>
  <div>
    <v-btn
      @click="
        tab++;
        nextEnabled = false;
        $emit('set_tab', tab);
        $emit('setActiveCategory', attractionCategories[tab]);
      "
      class="btn_inner"
      :style="isMobile && isMobileMenu ? 'bottom: 95px;' : ''"
      color="#e62076"
      dark
      v-if="nextEnabled && tab + 1 != attractionCategories.length && !pcVersion"
    >
      Next
    </v-btn>
    <v-tabs
      v-if="!pcVersion"
      grow
      color="#E62076"
      v-model="tab"
      class="pa-0 ma-0"
      :show-arrows="false"
    >
      <v-row class="pa-0 ma-0">
        <v-tab
          class="tab-custom"
          v-for="(category, index) in attractionCategories"
          :disabled="tab != index"
          :key="index"
          >{{ "mzt_category_label" | localize }} {{ category.uuid }}</v-tab
        >
      </v-row>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(category, index) in attractionCategories"
        :key="index"
      >
        <v-row :justify="pcVersion ? 'start' : 'center'" class="mb-4 mx-0">
          <v-col
            cols="6"
            v-for="attraction in category.attractions"
            :key="attraction.uid"
          >
            <attraction-card
              :attraction="attraction"
              :pcVersion="pcVersion"
              :choosed="
                selectedItems.filter((el) => {
                  return attraction.uid == el;
                })[0]
                  ? true
                  : false
              "
              :disableAgregarBtn="
                selectedItems.length >= getCount &&
                !selectedItems.filter((el) => {
                  return attraction.uid == el;
                })[0]
                  ? true
                  : false
              "
              @setSelectedAttraction="setSelectedAttraction"
              @removeAttractionUid="removeAttractionUid"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import attractionCard from "./attractionCard.vue";
import mobile from "@/mixins/mobileMixin";
//import AttractionsService from "@/requests/Attractions/AttractionsService";
import mztAttractionsMixin from "@/mixins/mztAttractionsMixin";
import _ from "lodash";

export default {
  components: { attractionCard },
  mixins: [mobile, mztAttractionsMixin],
  data: () => ({
    tab: 0,
    nextEnabled: false,
    selectedNow: [],
  }),
  props: {
    attractionCategories: {
      require: true,
    },
    disableAgregarBtn: {
      require: true,
    },
    selectedItems: {
      require: true,
      default: [],
    },
    pcVersion: {
      require: false,
      default: false,
    },
  },
  mounted() {
    try {
      const data = { ...this.getChoosedInfo };
      if (!_.isEmpty(data)) {
        this.tab = data.tab;
        if (this.selectedItems.length == this.getCount) {
          this.nextEnabled = true;
        } else {
          this.nextEnabled = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    setSelectedAttraction(uid) {
      //this.selectedNow = []
      this.$emit("setSelectedAttraction", uid);
    },
    removeAttractionUid(uid) {
      //this.selectedNow = []
      this.$emit("removeAttractionUid", uid);
    },
    resetData() {
      this.nextEnabled = false;
      this.selectedItems = [];
      this.tab = 0;
    },
    nextTab() {
      this.tab++;
      this.nextEnabled = false;

    },
  },
  watch: {
    selectedItems: {
      deep: true,
      handler() {
        console.log(this.selectedItems);
        if (this.selectedItems.length == this.getCount) {
          this.nextEnabled = true;
        } else {
          this.nextEnabled = false;
        }
      },
    },
    nextEnabled: {
      handler() {
        if (this.pcVersion) {
          console.log(this.nextEnabled);
          this.$emit("next_enabled", this.nextEnabled);
        }
      },
    },
  },
  computed: {
    getCount() {
      let count = 0;
      for (let i = 0; i < this.tab + 1; i++) {
        count += this.attractionCategories[i].count;
      }

      console.log(count);
      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  text-transform: none;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: unset;
  padding: 0px;
  margin-top: 18px;
}
.btn_inner {
  border-bottom: 2px solid #9a104c;
  border-radius: 10px !important;
  width: 342px !important;
  height: 44px !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  z-index: 110;
  left: 50%;
  margin-top: -22px;
  margin-left: -171px;
  text-transform: none;

  &-disabled {
    background-color: rgb(215, 215, 215) !important;
    border-bottom: 2px solid #acacac !important;
    pointer-events: none;
  }
}
</style>