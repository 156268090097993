<template>
  <div>
    <v-dialog v-model="attractionsModal" max-width="600px">
      <v-card style="position: relative">
        <div
          class="d-flex align-center"
          v-if="loading"
          style="min-height: 200px"
        >
          <v-progress-circular
            v-if="loading"
            class="mx-auto"
            indeterminate
            color="#E62076"
          ></v-progress-circular>
        </div>
        <div v-if="!loading">
          <v-icon
            style="position: absolute; right: 12px; top: 12px"
            large
            @click="attractionsModal = false"
            >mdi-close</v-icon
          >
          <v-card-title>
            {{ userLocale == "en" ? cityPass.title_en : cityPass.title_es }}
          </v-card-title>
          <v-card-text>
            <v-row align="center" class="mt-2" no-gutters>
              <v-col class="col-6">
                <div style="font-size: 19px">
                  <strong>
                    {{ "home_title_attractions" | localize }}
                  </strong>
                </div>
              </v-col>
              <v-col class="col-6 mr-auto" style="text-align: right">
                <div style="font-size: 19px" class="ml-auto">
                  <strong>
                    {{ "attractions_prices_label1" | localize }}
                  </strong>
                </div>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mt-2"
              no-gutters
              v-for="attraction in attractions"
              :key="attraction.uid"
            >
              <v-col class="col-7">
                <router-link
                  :to="'/attractions/' + attraction.uid"
                  class="text-decoration-none"
                >
                  <div class="title_attr">
                    {{ attraction.title }}
                  </div>
                </router-link>
              </v-col>
              <v-col class="text-right" style="font-size: 16px">
                <div class="ml-auto">
                  {{ Math.ceil(attraction.price_adult) }}/{{
                    Math.ceil(attraction.price_kid)
                  }}
                </div>
              </v-col>
            </v-row>
            <!-- <v-row justify="center" class="mt-3 mb-0">
              {{ "citypasses_view_fee_label" | localize }}
            </v-row> -->
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import { mapGetters } from "vuex";
export default {
  props: {
    cityPass: {
      required: true,
    },
  },
  data: () => ({
    attractionsModal: true,
    attractions: [],
    loading: false,
    userLocale: "",
  }),
  mounted() {
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
    this.loading = true;
    this.getAttractions();
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  methods: {
    getAttractions() {
      var attractions = [];
      let count = 0;
      if (!this.cityPass.attractions.length) {
        this.loading = false;
      } else {
        for (let i = 0; i < this.cityPass.attractions.length; i++) {
          AttractionService.getAttraction(
            this.cityPass.attractions[i].uuid
          ).then((res) => {
            console.log(res.result);
            count += 1;

            if (this.cityPass.attractions[i].is_additional != "1") {
              attractions.push({
                title: res.result.title,
                discount: parseInt(this.cityPass.attractions[i].discount * 100),
                is_free: this.cityPass.attractions[i].is_free,
                is_additional: this.cityPass.attractions[i].is_additional,
                uid: res.result.uid,
                price_kid: this.cityPass.attractions[i].price_kid,
                price_adult: this.cityPass.attractions[i].price_adult,
              });
            }

            if (count == this.cityPass.attractions.length) {
              // //this.loading = false;
              this.filterAttractions(attractions);
            }
          });
        }
      }

      console.log(attractions);
      // //this.attractions = attractions;

      // //console.log(this.attractions);
    },

    filterAttractions(attractions) {
      this.attractions = attractions.sort(function (a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      this.loading = false;
      // //this.attractions = attractions;
    },
  },
  watch: {
    attractionsModal: {
      handler() {
        this.attractionsModal = false;
        this.$emit("close_attractions_modal");
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
  },
};
</script>

<style scoped>
.title_attr {
  color: #e62076;
  font-size: 16px;
  font-weight: 500;
}

.title_attr:hover {
  color: #92194d;
}
</style>