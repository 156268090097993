var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.nextEnabled && _vm.tab + 1 != _vm.attractionCategories.length && !_vm.pcVersion)?_c('v-btn',{staticClass:"btn_inner",style:(_vm.isMobile && _vm.isMobileMenu ? 'bottom: 95px;' : ''),attrs:{"color":"#e62076","dark":""},on:{"click":function($event){_vm.tab++;
      _vm.nextEnabled = false;
      _vm.$emit('set_tab', _vm.tab);
      _vm.$emit('setActiveCategory', _vm.attractionCategories[_vm.tab]);}}},[_vm._v(" Next ")]):_vm._e(),(!_vm.pcVersion)?_c('v-tabs',{staticClass:"pa-0 ma-0",attrs:{"grow":"","color":"#E62076","show-arrows":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((_vm.attractionCategories),function(category,index){return _c('v-tab',{key:index,staticClass:"tab-custom",attrs:{"disabled":_vm.tab != index}},[_vm._v(_vm._s(_vm._f("localize")("mzt_category_label"))+" "+_vm._s(category.uuid))])}),1)],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.attractionCategories),function(category,index){return _c('v-tab-item',{key:index},[_c('v-row',{staticClass:"mb-4 mx-0",attrs:{"justify":_vm.pcVersion ? 'start' : 'center'}},_vm._l((category.attractions),function(attraction){return _c('v-col',{key:attraction.uid,attrs:{"cols":"6"}},[_c('attraction-card',{attrs:{"attraction":attraction,"pcVersion":_vm.pcVersion,"choosed":_vm.selectedItems.filter(function (el) {
                return attraction.uid == el;
              })[0]
                ? true
                : false,"disableAgregarBtn":_vm.selectedItems.length >= _vm.getCount &&
              !_vm.selectedItems.filter(function (el) {
                return attraction.uid == el;
              })[0]
                ? true
                : false},on:{"setSelectedAttraction":_vm.setSelectedAttraction,"removeAttractionUid":_vm.removeAttractionUid}})],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }