<template>
  <div>
    <v-card class="pa-4 br-12" :class="{ disabled: citypass.status == '0' }">
      <div
        :style="
          'width: 100%; height: auto; border-radius:8px; background-color: ' +
          citypass.color
        "
      >
        <v-row no-gutters align="center" justify="center" class="pt-3">
          <v-divider class="white mx-3" />
          <img
            class="pass-logo"
            :class="{ 'pass-logo-mzt': mazatlan }"
            :src="
              require('@/assets/' + $options.filters.city('white_main_logo'))
            "
          />
          <v-divider class="white mx-3" />
        </v-row>
        <v-row no-gutters class="my-1" align="center" justify="center">
          <div class="white--text">
            <h4 class="">
              {{ userLocale == "en" ? citypass.title_en : citypass.title_es }}
            </h4>
          </div>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <div class="white--text">
            <span class="subtitle-1">{{
              "city_passes_label_city_pass" | localize
            }}</span>
          </div>
        </v-row>
        <v-row align="center" justify="center" class="mt-3">
          <div class="white--text" v-if="!citypass.days && citypass.term != 28">
            <h3>
              {{ citypass.term }}
              {{ "city_passes_label_days" | localize }}
            </h3>
          </div>
          <div class="white--text" v-if="citypass.term == 28">
            <h3>1 {{ "city_passes_label_month" | localize }}</h3>
          </div>
          <div class="white--text" v-if="citypass.days">
            <h3 v-if="citypass.days == '1,2,3,4,5'">
              {{ "city_passes_Monday" | localize }} -
              {{ "city_passes_Friday" | localize }}
            </h3>
            <h3 v-if="citypass.days == '1,2,3,4,5,6,7'">
              {{ "city_passes_Monday" | localize }} -
              {{ "city_passes_Sunday" | localize }}
            </h3>
            <h3 v-if="citypass.days == '1,2,3,4'">
              {{ "city_passes_Monday" | localize }} -
              {{ "city_passes_Thursday" | localize }}
            </h3>
            <h3 v-if="citypass.days == '5,6,7'">
              {{ "city_passes_Friday" | localize }} -
              {{ "city_passes_Sunday" | localize }}
            </h3>
            <h3 v-if="citypass.days == '6,7'">
              {{ "city_passes_Saturday" | localize }} -
              {{ "city_passes_Sunday" | localize }}
            </h3>
          </div>
        </v-row>
      </div>
      <div class="card_inner text-center">
        <div class="subtitle-2 black--text">
          <div class="mt-5" v-if="!mazatlan" style="font-size: 17px">
            {{ "city_passes_get_discounts_label_1" | localize }}
            <span style="color: #e62076" class="font-weight-bold">
              {{ getMaxDiscount }}%
            </span>
            {{ "city_passes_get_discounts_label_2" | localize }}
          </div>
          <div class="mt-5" v-if="mazatlan">
            <span style="color: #e62076" class="font-weight-bold"
              >MX$
              {{
                citypass.price_adult
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</span
            >
            {{ "city_passes_label_adults" | localize }}
          </div>
          <div class="" v-if="mazatlan">
            <span style="color: #e62076" class="font-weight-bold"
              >MX$
              {{
                citypass.price_kid
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</span
            >
            {{ "city_passes_label_kids" | localize }}
          </div>
          <div v-if="mazatlan">
            <span class="font-weight-bold" style="color: #e62076">{{
              "infants_label" | localize
            }}</span>
          </div>
          <div class="mt-2" :style="mazatlan ? '' : 'font-size: 17px'">
            {{ "city_passes_label_included_for" | localize }}
            <span style="color: #e62076" class="font-weight-bold"
              >{{ citypass.term }}
              {{ "city_passes_label_days" | localize }}</span
            >
          </div>

          <div :style="mazatlan ? '' : 'font-size: 17px'">
            {{ "city_passes_label_your_choice" | localize }}
            <span style="color: #e62076" class="font-weight-bold"
              >{{ citypass.attraction_count }}
              {{ "city_passes_label_attractions" | localize }}</span
            >
          </div>

          <div class="mt-2">
            <span
              v-if="
                citypass.tequila_day == '1' ||
                citypass.hop_bus == '1' ||
                citypass.tequila_express == '1'
              "
              >+</span
            >
            <span v-if="citypass.hop_bus == '1'">{{
              "city_passes_checkbox_hop_on_bus" | localize
            }}</span>
            <span v-if="citypass.tequila_day == '1'"
              ><span v-if="citypass.hop_bus == '1'">,&nbsp;</span
              >{{ "city_passes_checkbox_day_in_tequila" | localize }}</span
            >
            <span v-if="citypass.tequila_express == '1'"
              ><span
                v-if="citypass.hop_bus == '1' || citypass.tequila_day == '1'"
                >,&nbsp;</span
              >{{
                "city_passes_checkbox_tequila_express_train" | localize
              }}</span
            >
          </div>

          <div class="mt-2" v-if="mazatlan">
            {{ "city_passes_label_and" | localize }}
            <span style="color: #e62076" class="font-weight-bold">{{
              parseInt(parseFloat(citypass.discount_attraction) * 100) +
              "-" +
              parseInt(parseFloat(citypass.discount_attraction_max) * 100)
            }}</span>
            {{ "city_passes_label_discount_part1" | localize }}
            {{ "city_passes_label_discount_part2" | localize }}
          </div>
          <div style="color: #e62076" class="font-weight-bold mt-2">
            {{
              userLocale == "en"
                ? citypass.description_en
                : citypass.description_es
            }}
          </div>
        </div>

        <div
          class="text-center mt-4"
          @click="attractionsModal = true"
          style="
            cursor: pointer;
            color: #3e81de;
            text-decoration: underline;
            font-size: 13px;
            font-weight: 500;
          "
        >
          {{ "city_passes_view_all_label" | localize }}
        </div>
      </div>
      <v-card-actions
        v-if="citypass.status == '1'"
        class="justify-center my-auto"
      >
        <v-btn
          color="#e62076"
          dark
          width="100%"
          height="40px"
          @click="$emit('set_citypass', citypass)"
          style="text-transform: unset !important"
        >
          {{ "city_passes_select_button" | localize }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <all-attractions-modal
      v-if="attractionsModal"
      @close_attractions_modal="attractionsModal = false"
      :cityPass="citypass"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import allAttractionsModal from "@/components/CityPasses/AllAttractionsModal.vue";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [cityMixin],
  components: {
    allAttractionsModal,
  },
  props: {
    citypass: {
      require: true,
    },
  },
  data: () => ({
    userLocale: "",
    attractionsModal: false,
  }),
  mounted() {
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    // getMinDiscount() {
    //   return parseInt(
    //     Math.min(
    //       ...this.citypass.attractions
    //         .filter((el) => el.discount)
    //         .map((el) => el.discount)
    //     ) * 100
    //   );
    // },
    getMaxDiscount() {
      return parseInt(
        Math.max(
          ...this.citypass.attractions
            .filter((el) => el.discount)
            .map((el) => el.discount)
        ) * 100
      );
    },
  },
  watch: {
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.user.locale;
      },
    },
  },
};
</script>

<style scoped>
.citypass-title {
  font-size: 24px;
  line-height: 31px;

  color: #343434;
}
.card_inner {
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  font-weight: bold;
}

.prices-values {
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}
.disabled {
  opacity: 0.2;
}
</style>