<template>
  <div>
    <v-btn
      @click="backToAttraction"
      class="mt-1"
      color="black"
      icon
      absolute
      x-large
    >
      <v-row no-gutters justify="center" align="center">
        <v-col class="col-12 pa-0">
          <!-- <v-icon x-large style="height: 100%; width: auto"
            >mdi-arrow-left
          </v-icon> -->
          <img src="@/assets/mobileBackButton.svg" />
        </v-col>
        <!-- <v-col class="col-12 pa-0" style="font-size: 12px; line-height: 12px"
          >back</v-col
        > -->
      </v-row>
    </v-btn>
    <v-row no-gutters justify="center" class="mb-5">
      <v-row no-gutters justify="center">
        <div class="step-value-grey text-center">
          <div class="step-number">1</div>
        </div>
        <div class="step-value-grey mx-2 text-center">
          <div class="step-number">2</div>
        </div>
        <div class="step-value text-center">
          <div class="step-number">3</div>
        </div>
      </v-row>
    </v-row>
    <v-row no-gutters justify="center" class="step-title">
      {{ "cart_details_label_checkout" | localize }}
    </v-row>
    <checkout-component />
  </div>
</template>

<script>
import CheckoutComponent from "../../Checkout/checkoutComponent.vue";
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    backToAttraction() {
      this.$router.push({ name: "mobile_attraction" });
    },
  },
  components: {
    CheckoutComponent,
  },
};
</script>