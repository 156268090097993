<template>
  <div>
    <v-row justify="center" class="mb-4 mt-1">
      <v-col
        cols="6"
        v-for="attraction in attractionList"
        :key="attraction.uid"
      >
        <attraction-card
          :attraction="attraction"
          :choosed="
            checkedAttraction.filter(
              (el) => el.attraction_uuid == attraction.uid
            ).length > 0
          "
          :disableAgregarBtn="
            checkedAttraction.length >= citypassFreeActtraction ||
            !startCitypassDate
          "
          @setSelectedAttraction="setSelectedAttraction"
          @removeAttractionUid="removeAttractionUid"
          @bookingAttraction="bookingAttraction"
          :key="key"
        /> </v-col
    ></v-row>
    <booking-attraction-modal
      v-if="showBookingAttractionModal"
      :visible="showBookingAttractionModal"
      :attraction="attractionForBooking"
      :startCitypassDate="startCitypassDate"
      :endCitypassDate="endCitypassDate"
      :adultNum="adultNumber"
      :childNum="childNumber"
      @chooseTime="chooseTimeForBookingAttraction"
      @close="showBookingAttractionModal = false"
    />
  </div>
</template>

<script>
import BookingAttractionModal from "../../../OurAttractions/bookingAttractionModal.vue";
import attractionCard from "./attractionCard.vue";
import { eventBus } from "@/main.js";
//import AttractionsService from "@/requests/Attractions/AttractionsService";
export default {
  components: { attractionCard, BookingAttractionModal },
  data: () => ({
    selectedAttractions: [],
    checkedAttraction: [],
    bookingAttractions: [],
    checkedNum: [],
    attractionForBooking: {},
    showBookingAttractionModal: false,
    adultNumber: 1,
    childNumber: 0,
    startCitypassDate: "",
    endCitypassDate: "",
    key: 0,
  }),
  props: {
    disableAgregarBtn: {
      require: true,
    },
    attractionList: {
      require: true,
    },
    citypassFreeActtraction: {
      require: true,
    },
  },
  created() {
    eventBus.$on(
      "changeData",
      (started_at, ended_at, quantity_adult, quantity_kid) => {
        this.startCitypassDate = started_at;
        this.endCitypassDate = ended_at;
        this.adultNumber = quantity_adult;
        this.childNumber = quantity_kid;
      }
    );
  },
  mounted() {
    if (localStorage.attractions) {
      this.checkedAttraction = JSON.parse(localStorage.attractions);
    }
  },
  methods: {
    setSelectedAttraction(uid) {
      if (
        this.checkedAttraction.findIndex((el) => el.attraction_uuid == uid) ==
        -1
      ) {
        this.checkedAttraction.push({
          attraction_uuid: uid,
        });
      }
    },
    removeAttractionUid(uid) {
      console.log("checkedattraction", this.checkedAttraction, uid);
      this.checkedAttraction = this.checkedAttraction.filter(
        (attraction) => attraction.attraction_uuid !== uid
      );
      console.log("checked", this.checkedAttraction);
      localStorage.setItem(
        "attractions",
        JSON.stringify(this.checkedAttraction)
      );
    },
    bookingAttraction(attraction) {
      let isBookedAttraction = this.bookingTime(attraction.uid);
      if (isBookedAttraction) {
        this.attractionForBooking = {
          ...attraction,
          eventUid: isBookedAttraction.uuid,
        };
      } else if (
        this.selectedAttractions.length < this.citypassFreeActtraction
      ) {
        this.attractionForBooking = attraction;
      }
      if (
        this.selectedAttractions.length < this.citypassFreeActtraction ||
        this.selectedAttractions.findIndex(
          (time) => time.attraction_uuid == attraction.uid
        ) !== -1
      ) {
        setTimeout(() => {
          this.showBookingAttractionModal = true;
        }, 200);
      }
    },
    bookingTime(uuid) {
      let time = this.selectedAttractions.filter(
        (attraction) => attraction.attraction_uuid == uuid
      );
      return time?.[0] || "";
    },
    chooseTimeForBookingAttraction(time) {
      let index = this.attractionList.findIndex(
        (attraction) => attraction.uid == this.attractionForBooking.uid
      );
      this.checkAttraction(index, this.attractionForBooking, true, time.uuid);
      let attractionIndex = this.bookingAttractions.findIndex(
        (attraction) =>
          attraction.attraction_uuid == this.attractionForBooking.uid
      );
      if (attractionIndex == -1) {
        this.bookingAttractions.push(time);
      } else {
        this.bookingAttractions[attractionIndex] = time;
      }
      localStorage.booking_attractions = JSON.stringify(
        this.bookingAttractions
      );
      this.showBookingAttractionModal = false;
      this.attractionForBooking = {};
      this.key++;
    },
    checkAttraction(index, attraction, showAgree, bookingEventUid) {
      if (
        attraction.is_booking_require == "Yes" &&
        this.checkedAttraction.length < this.citypassFreeActtraction &&
        !showAgree
      ) {
        this.checkAttractionInfo = {
          index: index,
          attraction: attraction,
        };
        this.showAgree = true;
        this.showModal(attraction.booking_requirements);
      } else {
        this.checkedAttraction.length < this.citypassFreeActtraction &&
        this.checkedAttraction.findIndex(
          (checked_attraction) => checked_attraction.uid == attraction.uid
        ) == -1
          ? ((this.checked = !this.checked),
            this.checkedNum.push(index),
            this.checkedAttraction.push({
              attraction_uuid: attraction.uid,
              bookingEventUid: bookingEventUid,
            }))
          : "";
      }
    },
  },
  watch: {
    checkedAttraction: {
      deep: true,
      handler() {
        this.$emit("setSelectedAttraction", this.checkedAttraction);
        console.log("work");
        this.key++;
      },
    },
  },
};
</script>

<style>
</style>